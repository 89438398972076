"use strict";
Object.defineProperty(exports, "__esModule", { value: true });

// Check if window is defined
const isWindowDefined = typeof window !== 'undefined';

// Use a local variable to store the __pan object
const pan = isWindowDefined && window.__pan ? window.__pan : {
    observer: null,
    clickListener: false,
    mouseoverListener: null,
    inertiaStartListener: null,
};

// Assign the pan object to window.__pan if window is defined
if (isWindowDefined) {
    window.__pan = pan;
}

if (pan.observer) {
    pan.observer.disconnect();
    pan.observer = null;
}

if (pan.clickListener) {
    document.removeEventListener("click", pan.clickListener);
    pan.clickListener = null;
}

if (pan.mouseoverListener) {
    document.removeEventListener("mouseover", pan.mouseoverListener);
    pan.mouseoverListener = null;
}

if (pan.inertiaStartListener) {
    document.removeEventListener("inertia:start", pan.inertiaStartListener);
    pan.inertiaStartListener = null;
}

(function () {
    var domObserver = function (callback) {
        var observer = new MutationObserver(callback);
        observer.observe(document.body, { childList: true, subtree: true });
        pan.observer = observer;
    };
    var queue = [];
    var queueTimeout = null;
    var impressed = new Set(); // Change to a Set for efficient lookup
    var hovered = [];
    var clicked = [];
    var shouldTrackEvent = function (element, eventType) {
        if (eventType === "click") {
            return false;
        }
        var eventList = element.getAttribute("data-pan-event");
        if (eventList === null) {
            return true; // If data-pan-event is not present, track all events
        }
        var events = eventList.split(",").map(function (e) { return e.trim(); });
        return events.includes(eventType);
    };
    var commit = function () {
        if (queue.length === 0) {
            return;
        }
        if (!window.amplitude) {
            return;
        }
        var onGoingQueue = queue.slice();
        queue = [];
        onGoingQueue.forEach(function (event) {
            window.amplitude.track(event.type, {
                name: event.name,
                attributes: event.attributes
            });
        });
    };
    var queueCommit = function () {
        queueTimeout && clearTimeout(queueTimeout);
        // @ts-ignore
        queueTimeout = setTimeout(commit, 1000);
    };
    var send = function (el, event) {
        var target = el.target;
        var element = target.closest("[data-pan]");
        if (element === null) {
            return;
        }
        var name = element.getAttribute("data-pan");
        if (name === null) {
            return;
        }
        if (event === "hover") {
            if (hovered.includes(name)) {
                return;
            }
            hovered.push(name);
        }
        if (event === "click") {
            if (clicked.includes(name)) {
                return;
            }
            clicked.push(name);
        }

        if (!shouldTrackEvent(element, event)) {
            return;
        }
        var attributes = {};
        for (var i = 0; i < element.attributes.length; i++) {
            var attr = element.attributes[i];
            attributes[attr.nodeName] = attr.nodeValue;
        }
        attributes["element_text"] = element.innerText;
        attributes["element_tag"] = element
        attributes["page_url"] = window.location.href;
        attributes["page_title"] = document.title;
        queue.push({
            type: event,
            name: name,
            attributes: attributes,
        });
        queueCommit();
    };
    var debounce = function(func, wait) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            clearTimeout(timeout);
            timeout = setTimeout(function() {
                func.apply(context, args);
            }, wait);
        };
    };

    var detectImpressions = function () {
        var elementsBeingImpressed = document.querySelectorAll("[data-pan]");
        elementsBeingImpressed.forEach(function (element) {
            var name = element.getAttribute("data-pan");
            if (name === null || impressed.has(name)) {
                return;
            }

            if (!shouldTrackEvent(element, "impression")) {
                return;
            }

            // Create an Intersection Observer
            var observer = new IntersectionObserver(function(entries) {
                entries.forEach(function(entry) {
                    if (entry.isIntersecting && !impressed.has(name)) {
                        impressed.add(name);

                        var attributes = {};
                        for (var i = 0; i < element.attributes.length; i++) {
                            var attr = element.attributes[i];
                            attributes[attr.nodeName] = attr.nodeValue;
                        }
                        attributes["element_text"] = element.innerText;
                        attributes["element_tag"] = element.tagName.toLowerCase();
                        attributes["page_url"] = window.location.href;
                        attributes["page_title"] = document.title;

                        queue.push({
                            type: "impression",
                            name: name,
                            attributes: attributes,
                        });

                        queueCommit();
                        observer.disconnect(); // Stop observing once impression is recorded
                    }
                });
            }, { threshold: 0.5 }); // Element is considered visible when 50% is in view

            observer.observe(element);
        });
    };

    var debouncedDetectImpressions = debounce(detectImpressions, 250);

    // Detect impressions on scroll
    if (isWindowDefined) {
        window.addEventListener('scroll', debouncedDetectImpressions);
    }

    // Detect impressions on DOM changes
    domObserver(function () {
        impressed.forEach(function (name) {
            var element = document.querySelector("[data-pan='".concat(name, "']"));
            if (element === null) {
                impressed = impressed.filter(function (n) { return n !== name; });
                hovered = hovered.filter(function (n) { return n !== name; });
                clicked = clicked.filter(function (n) { return n !== name; });
            }
        });
        debouncedDetectImpressions();
    });

    // Detect impressions on view change (for single-page applications)
    var handleViewChange = function() {
        impressed.clear(); // Clear the Set instead of reassigning
        hovered = [];
        clicked = [];
        debouncedDetectImpressions();
    };

    // Listen for popstate event (browser back/forward)
    if (isWindowDefined) {
        window.addEventListener('popstate', handleViewChange);
    }

    // Override history.pushState and history.replaceState
    if (isWindowDefined && window.history) {
        var pushState = window.history.pushState;
        window.history.pushState = function() {
            pushState.apply(window.history, arguments);
            handleViewChange();
        };

        var replaceState = window.history.replaceState;
        window.history.replaceState = function() {
            replaceState.apply(window.history, arguments);
            handleViewChange();
        };
    }

    pan.clickListener = function (event) { return send(event, "click"); };
    document.addEventListener("click", pan.clickListener);
    pan.mouseoverListener = function (event) {
        return send(event, "hover");
    };
    document.addEventListener("mouseover", pan.mouseoverListener);
    pan.inertiaStartListener = function (event) {
        handleViewChange();
    };
    document.addEventListener("inertia:start", pan.inertiaStartListener);
    pan.beforeUnloadListener = function (event) {
        if (queue.length === 0) {
            return;
        }
        commit();
    };
    if (isWindowDefined) {
        window.addEventListener("beforeunload", pan.beforeUnloadListener);
    }
})();

// Export the pan object for use in other modules
exports.pan = pan;
