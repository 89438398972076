import React from 'react';
import { Component } from '@/components/chart';
import { TopBarChart } from '@/components/bar';
import { TopBarChart2 } from '@/components/bar2';
import { MyPieChart } from '@/components/pie';

export default function (props) {
    return <>
        <div className="flex flex-col gap-y-10">
            <div>
                <Component />
            </div>

            <div className="flex gap-x-10 w-full">
                <TopBarChart />
                <MyPieChart />
                <TopBarChart2 />
            </div>
        </div>
    </>;
}
